<template>
  <v-dialog width="680" v-model="opened" :fullscreen="$vuetify.breakpoint.smAndDown" transition="slide-x-transition">
    <div
      style="border-radius: 20px !important;"
      class="expande-horizontal column pt-0"
    >
      <v-flex xs12>
        <div class="windows-style expande-horizontal fonte column">
          <transition name="slide-fade">
            <v-list-item class="pa-0 ma-0 pl-2">
              <v-list-item-content>
                <v-list-item-title class="">
                  Disponibilidade
                </v-list-item-title>
                <v-list-item-subtitle class="font-mini">
                  Gerencie os detalhes abaixo
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="expande-horizontal centraliza">
                  <!-- <v-btn
                    color="red"
                    @click="cancel"
                    small
                    rounded
                    v-if="payload._id"
                    class="elevation-0 mr-1 fonte white--text text-lowercase"
                    >Cancelar</v-btn
                  > -->
                  <v-avatar
                    @click="close"
                    style="cursor: pointer;"
                    size="27"
                    :color="$theme.primary"
                    class="mr-2"
                    icon
                  >
                    <v-icon size="17" color="#f7f7f7">mdi-close</v-icon>
                  </v-avatar>
                </div>
              </v-list-item-action>
            </v-list-item>
          </transition>
        </div>
      </v-flex>
      <div
        :style="
          `${
            $vuetify.breakpoint.lgAndUp
              ? 'max-height: 70vh; overflow: auto;'
              : ''
          }`
        "
        class="windows-style-content expande-horizontal wrap"
      >
        <v-flex class="px-3" xs12>
          <v-switch label="Repetir semanalmente" v-model="payload.repeat_every_week"></v-switch>
        </v-flex>
        <v-flex class="py-3" xs12>
          <v-form ref="form">
            <div class="expande-horizontal wrap">
              <v-flex class="px-2 mb-2" xs12>
                <div class="expande-horizontal centraliza">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="payload.visit_date"
                        label="Data do agendamento"
                        hide-details
                        v-bind="attrs"
                        filled
                        :rules="[v => !!v || 'Preencha este campo']"
                        :color="$theme.primary"
                        @blur="parseDate(payload.visit_date)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateUnformatted"
                      no-title
                      :color="$theme.primary"
                      @input="formatDate(dateUnformatted)"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-flex>
              <v-flex class="px-2 mb-2" xs12 md6>
                <v-card class="expande-horizontal centraliza column">
                  <v-list width="100%" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="fonte">
                          Hora de Início
                        </v-list-item-title>
                        <v-list-item-subtitle :class="{ 'font-weight-bold blue--text': payload.visit_hour_start }">
                          {{ payload.visit_hour_start || 'Defina a hora de início abaixo' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="resetStartHour" class="fonte" small v-if="payload.visit_hour_start">Limpar</v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                  <v-time-picker
                    format="24hr"
                    ref="startHour"
                    full-width
                    :rules="[v => !!v || 'Preencha este campo']"
                    v-if="show_time_picker_start"
                    label="Hora de início"
                    no-title
                    v-model="payload.visit_hour_start"
                  ></v-time-picker>
                </v-card>
              </v-flex>
              <v-flex class="px-2 mb-2" xs12 md6>
                <v-card class="expande-horizontal centraliza column">
                  <v-list width="100%" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="fonte">
                          Hora de Término
                        </v-list-item-title>
                        <v-list-item-subtitle :class="{ 'font-weight-bold blue--text': payload.visit_hour_end }">
                          {{ payload.visit_hour_end || 'Defina a hora de término abaixo' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="resetEndHour" class="fonte" small v-if="payload.visit_hour_end">Limpar</v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                  <v-time-picker
                    format="24hr"
                    full-width
                    ref="endHour"
                    :rules="[v => !!v || 'Preencha este campo']"
                    v-if="show_time_picker_end"
                    label="Hora de término"
                    no-title
                    v-model="payload.visit_hour_end"
                  ></v-time-picker>
                </v-card>
              </v-flex>
            </div>
          </v-form>
        </v-flex>
      </div>
      <v-flex style="background-color: #f2f2f2;" class="pa-2" xs12>
        <div
          class="expande-horizontal centraliza"
        >
          <v-spacer></v-spacer>
          <v-btn
            v-if="!payload._id"
            dark
            depressed rounded
            @click="verify_if_hour_end_is_after_start"
            :color="$theme.primary"
          >
            <span class="fonte white--text"> Cadastrar disponibilidade </span>
          </v-btn>
          <v-btn rounded depressed v-else dark @click="verify_if_hour_end_is_after_start" :color="$theme.primary">
            <span class="fonte white--text"> Atualizar disponibilidade </span>
          </v-btn>
        </div>
      </v-flex>
    </div>
    <DeliveryInput
      ref="deliveryInput"
      @input="payload = { ...payload, address_destiny: $event }"
      v-model="payload.address_destiny"
    />
    <ModalCreateClient />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeliveryInput from "./DeliveryInput.vue";
import ModalCreateClient from "@/apps/44#Clientes/components/modalView.vue";

export default {
  props: ["origin"],
  computed: {
    ...mapGetters(["get_clientes", "getLoggedUser", "get_funcionarios"])
  },
  data() {
    return {
      opened: false,
      show_time_picker_start: true,
      show_time_picker_end: true,
      payload: {
        type: 'availability',
        visit_date: null,
        visit_hour_start: null,
        visit_hour_end: null,
      },
      from: "",
      dateUnformatted: null,
      menu: false,
      types: [
        {
          name: "Disponibilidade",
          value: "availability"
        },
        {
          name: "Visita",
          value: "visit"
        },
        {
          name: "Data limite",
          value: "limit_date"
        },
        {
          name: "Entrega",
          value: "delivery"
        },
        {
          name: "Ligação",
          value: "ligação"
        },
        {
          name: "E-mail",
          value: "email"
        },
        {
          name: "Whatsapp",
          value: "whatsapp"
        },
        {
          name: "Outros",
          value: "other"
        }
      ]
    };
  },
  components: {
    DeliveryInput,
    ModalCreateClient
  },
  methods: {
    ...mapActions([
      "abre_modal_view_cliente",
      "listar_my_external_movimentations",
      "createGlobalMessage"
    ]),
    verify_if_hour_end_is_after_start() {
      if (
        this.payload.visit_hour_start &&
        this.payload.visit_hour_end &&
        this.payload.visit_date
      ) {
        const start = this.$moment(
          `${this.payload.visit_date} ${this.payload.visit_hour_start}`,
          "DD/MM/YYYY HH:mm"
        );
        const end = this.$moment(
          `${this.payload.visit_date} ${this.payload.visit_hour_end}`,
          "DD/MM/YYYY HH:mm"
        );
        if (end.isBefore(start)) {
          this.$store.dispatch("createGlobalMessage", {
            type: "warning",
            message: "Hora de término não pode ser antes da hora de início",
            timeout: 4000
          });
          this.resetEndHour()
        } else {
          this.valida_form();
        }
      }
    },
    resetStartHour() {
      this.show_time_picker_start = false;
      this.payload.visit_hour_start = null;
      setTimeout(() => {
        this.show_time_picker_start = true;
      }, 300);
    },
    resetEndHour() {
      this.show_time_picker_end = false;
      this.payload.visit_hour_end = null;
      setTimeout(() => {
        this.show_time_picker_end = true;
      }, 300);
    },
    selectType(type) {
      this.payload = {
        ...this.payload,
        type
      };
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      this.payload.visit_date = `${day}/${month}/${year}`;
      this.menu = false;
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      this.dateUnformatted = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    divideScheduling(payload) {
      // Definir o intervalo de tempo
      const inicio = this.$moment(payload.visit_date + ' ' + payload.visit_hour_start, 'DD/MM/YYY HH:mm');
      const fim = this.$moment(payload.visit_date + ' ' + payload.visit_hour_end, 'DD/MM/YYY HH:mm');
      // Definir o intervalo desejado (15 minutos)
      const intervaloMinutos = 2;
      // Criar um array para armazenar os intervalos
      const intervalos = [];
      // Inicializar a variável de controle
      let atual = inicio.clone();
      // Loop para gerar os intervalos
      while (atual.isBefore(fim)) {
        const proximo = atual.clone().add(intervaloMinutos, 'minutes');
        intervalos.push({
          repeat_every_week: payload.repeat_every_week,
          visit_date: payload.visit_date,
          visit_hour_start: atual.format('HH:mm'), 
          visit_hour_end: proximo.format('HH:mm') 
        });
        atual = proximo;
      }

      return intervalos;
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.opened = false;
        let use_case = "";
        if (this.payload._id) {
          use_case = "external_movimentations/update-external-movimentation";
        }
        if (this.from === "sale" && !this.payload._id) {
          use_case = "external_movimentations/create-from-transaction";
        }
        if (this.from !== "sale" && !this.payload._id) {
          use_case = "external_movimentations/create-from-calendar";
        }
        if (this.payload._id) {
          this.$run(use_case, this.payload)
            .then((res) => {
              this.$store.dispatch("createGlobalMessage", {
                type: "success",
                message: "Disponibilidade atualizada 😎",
                timeout: 2000
              });
              if (this.origin && this.origin === "sale-body") {
                this.$store.commit('set_gestordepedido', {
                  ...this.$store.getters.get_gestordepedido,
                  external_movimentation: res.data
                })
              }
              this.listar_my_external_movimentations();
              this.close();
            })
            .catch(e => {
              this.$store.dispatch("createGlobalMessage", {
                type: "warning",
                message: e.response.data.message,
                timeout: 2000
              });
              this.opened = true;
            });
        } else {
          const getChunkSchedule = this.divideScheduling(this.payload);
          this.$run(use_case, { chunks: getChunkSchedule })
            .then(() => {
              this.$store.dispatch("createGlobalMessage", {
                type: "success",
                message: "Disponibilidade agendada 🚀",
                timeout: 2000
              });
              if (this.origin && this.origin === "sale-body") {
                this.$store.commit('set_gestordepedido', {
                  ...this.$store.getters.get_gestordepedido,
                  external_movimentation: res.data
                })
              }
              this.listar_my_external_movimentations();
              this.close();
            })
            .catch(e => {
              this.$store.dispatch("createGlobalMessage", {
                type: "warning",
                message: e.response.data.message,
                timeout: 2000
              });
              this.opened = true;
            });
        }
      }
    },
    isBeforeToday(date) {
      return this.$moment(date, 'DD/MM/YYYY').isBefore(this.$moment());
    },
    open(payload, from) {
      if (from === "sale") {
        this.payload = {
          transaction: payload._id,
          client: payload.compra.cliente,
          address_destiny: payload.compra.address || {}
        };
      }
      if (from === "open-event") {
        this.payload = payload;
      }
      if (from === "create-event") {
        // if (this.isBeforeToday(this.formatDate(payload.date))) {
        //   this.createGlobalMessage({
        //     type: "error",
        //     timeout: 4000,
        //     message: "Escolha uma data futura para cadastrar seus horários"
        //   });
        //   return;
        // } else {
          this.payload = {
            visit_date: this.formatDate(payload.date)
          };
          this.dateUnformatted = payload.date;
        // }
      }
      if (!from && payload) {
        this.payload = payload;
      }
      if (!from && !payload) {
        this.payload = {
          type: 'availability',
        };
      }
      this.from = from;
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.payload = {};
    }
  }
};
</script>
