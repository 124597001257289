<template>
  <v-dialog persistent width="600" v-model="opened">
    <v-flex class="fonte" xs12>
      <v-card style="border-radius: 12px;">
        <v-flex xs12>
          <div class="windows-style" style="border-top-right-radius: 12px; border-top-right-radius: 12px;">
            <div class="expande-horizontal">
              <v-flex xs12>
                <v-list color="transparent" dense nav>
                  <v-list-item v-if="step == '0'">
                    <v-avatar @click="opened = false" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">Solicitar Saque</span>
                      </v-list-item-title>
                      <span class="fonte grey--text"> informe a quantia e a conta bancária </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="step == '1'">
                    <v-avatar @click="opened = false" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">Cadastro de conta</span>
                      </v-list-item-title>
                      <span class="fonte grey--text"> informe os dados da conta </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
          </div>
        </v-flex>
        <div class="pa-3 windows-style-content">
          <v-window touchless v-model="step">
            <v-window-item value="0">
              <div class="expande-horizontal centraliza wrap">
                <v-flex xs12>
                  <v-text-field
                    v-model="withdraw.value"
                    label="Valor"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    v-mask="['##,##','###,##', '####,##', '#####,##']"
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="withdraw.bank_account"
                    :items="bank_accounts"
                    item-text="description"
                    item-value="_id"
                    label="Conta Bancária"
                    no-data-text="Nenhuma conta bancária cadastrada"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    v-mask="['##,##','###,##', '####,##', '#####,##']"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="mb-3">
                  <div class="expande-horizontal">
                    <v-spacer></v-spacer>
                    <v-btn @click="setStep('1')" :color="$theme.primary" small text>Cadastrar nova conta bancária</v-btn>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-1" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn rounded class="" color="primary" @click="requestWithdraw">Solicitar saque </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
            <v-window-item value="1">
              <div class="expande-horizontal centraliza wrap">

                <v-flex class="pb-1 pt-1" xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-text-field
                        dense
                        v-model="bank_account.description"
                        label="Apelido da conta"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        dense
                        v-model="bank_account.bank"
                        label="Banco"
                        placeholder="ex: Banco do Brasil, Caixa Econômica..."
                        :rules="[v => !!v || 'Campo obrigatório']"
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        dense
                        v-model="bank_account.agency"
                        label="Agência"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        dense
                        v-model="bank_account.account_type"
                        label="Tipo de conta"
                        placeholder="ex: pix, conta corrente, poupança..."
                        :rules="[v => !!v || 'Campo obrigatório']"
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        dense
                        v-model="bank_account.account"
                        label="Número da conta"
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        dense
                        v-model="bank_account.pix"
                        label="Pix"
                        filled
                      ></v-text-field>
                    </v-flex>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-1" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn rounded class="mr-2" color="primary" @click="setStep('0')">Voltar </v-btn>
                    <v-btn :loading="loading" rounded class="" color="primary" @click="createBankAccount">Cadastrar conta </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';
import TriagemFiles from './TriagemFiles.vue';
  export default {
    components: {
      LottieAnimation,
      TriagemFiles
    },
    methods: {
      setStep(step) {
        this.step = `${step}`
      },
      createBankAccount() {
        this.loading = true;
        this.$run('bank-account/create', this.bank_account)
        .then(() => {
          this.bank_account = {};
          this.loading = false;
          this.$store.dispatch('createGlobalMessage', {
            type: 'success',
            message: 'Conta bancária cadastrada com sucesso!',
            timeout: 5000
          })
          this.listMyBankAccounts();
          this.step= '0'
        })
      },
      requestWithdraw() {
        this.loading = true;
        this.$run('funcionarios/request-withdraw', this.withdraw)
        .then(() => {
          this.step= '0'
          this.loading = false;
          this.$store.dispatch('createGlobalMessage', {
            type: 'success',
            message: 'Seu saque foi solicitado!',
            timeout: 5000
          })
          this.$emit('update-request');
        })
        this.opened = false
        this.step = '0'
      },
      open() {
        this.opened = true
      },
      listMyBankAccounts() {
        this.$run('bank-account/list')
          .then(res => {
            this.bank_accounts = res.data;
          })
      }
    },
    data() {
      return {
        bank_accounts: [],
        bank_account: {},
        withdraw: {},
        value: "",
        loading: false,
        opened: false,
        step: '0',
      }
    },
    created() {
      this.listMyBankAccounts();
    }
  }
</script>