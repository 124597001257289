<template>
  <layout :title="$route.name" :rotas="rotas" :options="options">
      <div v-if="false" style="background: rgba(0,0,0,0.1); min-height: 100vh;" class="centraliza expande-horizontal wrap">
        <v-flex class="px-4" xs12 md8>
          <div
            class="expande-horizontal centraliza fonte pa-3 column cool-shaddow"
            style="justify-content: center; min-height: 20vh; background: #f7f7f7; border-radius: 16px; border: 1px solid #e5e5e5"
          >
            <div class="horizontal-scroll">
              <div
                class="infinity-hour-container"
                @mousedown="startDrag"
                @touchstart="startDrag"
                @mousemove="drag"
                @touchmove="drag"
                @mouseup="endDrag"
                @touchend="endDrag"
              >
                <div class="not-selectable" style="min-width: 200px;">
                  teste
                </div>
                <div class="not-selectable" style="min-width: 200px;">
                  teste
                </div>
                <div class="not-selectable" style="min-width: 200px;">
                  teste
                </div>
                <div class="not-selectable" style="min-width: 200px;">
                  teste
                </div>
                <div class="not-selectable" style="min-width: 200px;">
                  teste
                </div>
              </div>
            </div>
  
            <span @click="openModalChangeStatus" class="not-selectable" style="cursor: pointer; border: 1px solid #e5e5e5; padding: 3px; border-radius: 3px;">
              <v-icon :color="processStatus(my_status).color" size="11">mdi-circle</v-icon>
              {{ processStatus(my_status).name }}
            </span>
            <span class="fonteMini mb-3 mt-1 grey--text" style="font-style: italic;">{{ processStatus(my_status).message }}</span>
            
            <span style="font-size: 12pt;"
              class="px-6 mb-0 text-center black--text" 
              >Olá Dr(ª) {{ getLoggedUser.nome.split(" ")[0] }}, que bom ter você conosco!</span
            >
            <span class="mt-0 mb-6 px-6 text-center grey--text"
              ></span
            >
            <div id="container-menus" class="expande-horizontal centraliza wrap">
              <v-card
                v-for="(menu, index) in menus"
                :key="`item-menu-${index}`"
                v-show="menu.permission()"
                width="85"
                :color="$theme.primary"
                class="mr-2 mb-2 py-2"
                @click="menu.action"
                hover
                style="border-radius: 12px;"
              >
                <div
                  style="min-height: 60px; max-height: 60px;"
                  class="expande-horizontal column centraliza"
                >
                  <v-icon color="white" size="30">{{
                    menu.icon
                  }}</v-icon>
                  <v-spacer></v-spacer>
                  <span class="fonteMini white--text text-center">{{
                    menu.name
                  }}</span>
                </div>
              </v-card>
            </div>
          </div>
        </v-flex>
        <v-dialog v-model="modal_change_status" width="300">
          <v-card width="300">
            <v-list dense>
              <v-list-item @click="changeMyStatus('available')">
                <v-list-item-content>
                  <v-list-item-title>Disponível</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon size="11" color="green accent-3">mdi-circle</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="changeMyStatus('busy')">
                <v-list-item-content>
                  <v-list-item-title>Ocupado</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon size="11" color="orange">mdi-circle</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="changeMyStatus('offline')">
                <v-list-item-content>
                  <v-list-item-title>Offline</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon size="11">mdi-circle</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
      </div>
      <div class="expande-horizontal column" style="max-height: 95vh; overflow: auto;justify-content: flex-start; align-items: flex-start; background: #fff;">
        <div class="expande-horizontal column">
          <!-- <span style="font-size: 24pt; font-family: 'Barlow Condensed', sans-serif;"
            class="px-3 mb-0 black--text" 
            >R$ 0,00</span
          > -->
          <span style="font-size: 24pt; font-family: 'Barlow Condensed', sans-serif;"
            class="px-4 mb-0 black--text" 
            >Olá Dr(ª) {{ getLoggedUser.nome.split(" ")[0] }}</span
          >
          <span style="position: relative; top: -14px;font-size: 12pt; font-family: 'Barlow Condensed', sans-serif;"
            class="px-4 mb-0 mt-0 pt-0 grey--text" 
            >Que bom ter você conosco!</span
          >
          <!-- <span @click="openModalChangeStatus" class="not-selectable" style="cursor: pointer; border: 1px solid #e5e5e5; padding: 3px; border-radius: 3px;">
            <v-icon :color="processStatus(my_status).color" size="11">mdi-circle</v-icon>
            {{ processStatus(my_status).name }}
          </span>
          <span class="fonteMini mb-3 mt-1 grey--text" style="font-style: italic;">{{ processStatus(my_status).message }}</span>
           -->
          
        </div>
        <div class="expande-horizontal column">
          <v-tabs :background-color="$theme.primary" dark v-if="$vuetify.breakpoint.lgAndUp" icons-and-text>
            <v-tab @click="tabs = 0">
              <span class="mt-2 fonte fonteMini text-capitalize">
                Minha Dispobilidade
              </span>
              <v-icon>
                mdi-calendar-badge-outline
              </v-icon>
            </v-tab>
            <v-tab @click="tabs = 1">
              <span class="mt-2 fonte fonteMini text-capitalize">
                Minhas Consultas
              </span>
              <v-icon>
                mdi-camera-front-variant
              </v-icon>
            </v-tab>
            <v-tab @click="tabs = 2">
              <span class="mt-2 fonte fonteMini text-capitalize">
                Meu Financeiro
              </span>
              <v-icon>
                mdi-cash
              </v-icon>
            </v-tab>
          </v-tabs>
          <v-bottom-navigation dark :background-color="$theme.primary" fixed bottom v-else>
            <v-btn 
              :value="menu.name"
              v-for="(menu, index) in menus"
              active-class="white--text"
              :key="`item-menu-${index}`"
              @click="menu.action2"
              v-show="menu.permission()"
              class="px-6"
            >
              <span class="fonte fonteMini">{{ menu.name }}</span>
  
              <v-icon>{{ menu.icon }}</v-icon>
            </v-btn>
          </v-bottom-navigation>
          <v-divider v-if="$vuetify.breakpoint.lgAndUp"></v-divider>
        </div>
        <div class="expande-horizontal" style="overflow: auto;">
          <MyExternalTasks v-if="tabs === 0" />
          <Consults v-if="tabs === 1" />
          <DoctorWithdraws v-if="tabs === 2" />
        </div>
    </div>
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import { driver } from "driver.js";
import MyExternalTasks from "@/apps/ExternalMovimentation/views/MyExternalTasksAvulse.vue";
import Consults from "@/apps/GestorDePedido/views/MainDoctorAvulse.vue";
import DoctorWithdraws from "@/apps/DoctorWithdraw/views/DoctorWithdraws.vue";

export default {
  components: {
    MyExternalTasks,
    Consults,
    DoctorWithdraws
  },
  data() {
    return {
      tabs: 0,
      modal_change_status: false,
      my_status: '',
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        }
      ],
      new_exam_modal: false,
      new_consult_modal: false,
      mouseIsDown: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0,
      checking: false,
      driver: null,
      options: [
        
      ],
      menus: [
        {
          icon: "mdi-calendar-badge-outline",
          name: "Disponibilidade",
          route: "/minha-agenda",
          action: () => this.$router.push("/minha-agenda"),
          action2: () => this.tabs = 0,
          permission: () => true
        },
        {
          icon: "mdi-camera-front-variant",
          name: "Minhas Consultas",
          route: "/consultas-medico",
          action: () => this.$router.push("/consultas-medico"),
          action2: () => this.tabs = 1,
          permission: () => true
        },
        {
          icon: "mdi-cash",
          name: "Meu Financeiro",
          route: "/meus-saques",
          action: () => this.$router.push("/minha-agenda"),
          action2: () => this.tabs = 2,
          permission: () => true
        },
        // {
        //   icon: "mdi-calendar-badge-outline",
        //   name: "Consultas Agendadas",
        //   route: "/minha-agenda",
        //   permission: () => this.get_permission_user["minha-agenda"]
        // },
        // {
        //   icon: "mdi-calendar-edit-outline",
        //   name: "Agenda",
        //   route: "/agenda",
        //   permission: () => this.get_permission_user["agenda"]
        // },
        // {
        //   icon: "mdi-library-outline",
        //   name: "Tipo de atendimento",
        //   route: "/insumos",
        //   permission: () => this.get_permission_user["produtos"]
        // },
      ]
    };
  },
  computed: {
    ...mapGetters(["get_dashboard", "getLoggedUser", "get_permission_user"])
  },
  methods: {
    startDrag(event) {
      this.mouseIsDown = true;
      if (this.dragActive) return;
      const content = document.getElementsByClassName(
        "infinity-hour-container"
      )[0];
      this.isDragging = true;
      this.startDragX = event.clientX || event.touches[0].clientX;
      this.startScrollLeft = content.scrollLeft;
    },
    drag(event) {
      const content = document.getElementsByClassName(
        "infinity-hour-container"
      )[0];
      if (this.isDragging) {
        const scrollAmount =
          this.startScrollLeft +
          (this.startDragX - (event.clientX || event.touches[0].clientX));
        content.scrollLeft = scrollAmount;
      }
    },
    endDrag() {
      this.mouseIsDown = false;
      if (this.dragActive) return;
      setTimeout(() => {
        this.isDragging = false;
        this.checking = false;
      }, 2000);
    },
    openModalChangeStatus() {
      this.modal_change_status = false;
      this.modal_change_status = true;
    },
    changeMyStatus(status) {
      this.my_status = status;
      this.modal_change_status = false;
      this.$run('transacoes/change-my-status', { status })
        .then(res => {
          this.my_status = status;
        })
        .catch(err => {
          this.my_status = '';
        })
    },
    processStatus(status) {
      if (status === '' || !status) return {
        name: 'Offline',
        color: 'grey',
        message: 'Você não receberá novas consultas'
      }
      const s = {
        'available': {
          name: 'Disponível',
          color: 'green accent-3',
          message: 'Você receberá novas consultas caso sua disponibilidade esteja aberta'
        },
        'busy': {
          name: 'Ocupado',
          color: 'red accent-3',
          message: 'Você não receberá novas consultas até mudar para Disponível, mesmo que suas disponibilidade esteja aberta'
        },
        'offline': {
          name: 'Offline',
          color: 'grey',
          message: 'Você não receberá consultas'
        },
        'notified': {
          name: 'Nofiicado',
          color: 'orange',
          message: 'Você tem uma consulta aguardando, entre em Minhas Consultas para visualizar'
        },
      }
      return s[status];
    },  
    mountDriver() {
      this.driver = driver({
        showProgress: true,
        steps: [
          { 
            element: '#container-menus', 
            popover: { 
              title: 'Bem vindo', 
              description: 'Na dashboard você visualiza os menus que você tem acesso.',
              doneBtnText: 'Sair',
              closeBtnText: 'Fechar',
              nextBtnText: 'Próximo',
              prevBtnText: '', 
            } 
          },
          { 
            element: '#options-container', 
            popover: { 
              title: 'Botões de ação', 
              description: 'Ações da página sempre aparecerão aqui.',
              doneBtnText: 'Sair',
              closeBtnText: 'Fechar',
              nextBtnText: 'Próximo',
              prevBtnText: 'Anterior', 
            } 
          },
          { 
            element: '#notifications-container', 
            popover: { 
              title: 'Notificações', 
              description: 'Quando algo precisar da sua atenção, você será avisado com uma.',
              doneBtnText: 'Sair',
              closeBtnText: 'Fechar',
              nextBtnText: 'Próximo',
              prevBtnText: 'Anterior', 
            } 
          },
          // { element: '.top-nav', popover: { title: 'Title', description: 'Description' } },
          // { element: '.sidebar', popover: { title: 'Title', description: 'Description' } },
          // { element: '.footer', popover: { title: 'Title', description: 'Description' } },
        ]
      });
    },
    driverStart() {
      this.driver.drive();
    }
  },
  mounted() {
    this.$run('transacoes/get-my-status', { status: 'available' })
      .then(res => {
        this.my_status = res.data.status;
      })
  }
  // created() {
  //   this.mountDriver();
  // }
};
</script>

<style>
.fonte-color {
  color: #075059;
}
.icon-show-balance {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px dashed #000;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.divider-balance {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #000;
}
.infinity-hour-container {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  right: 0;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  touch-action: pan-y;
  min-width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  right: 0;
  z-index: 0;
}
.horizontal-scroll {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  padding-left: 17px;
}
</style>
